import React, {
  Component,
  Fragment,
} from 'react';

import { ImgTributario } from '../../images/servicios';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class Diagnostico extends Component {
  render = () => {
    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo="Creación de Empresa" />
        <section className="welcome-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Somos Contasi</div>
                    <h2>DIAGNOSTICO Y REVISIÓN TRIBUTARIA</h2>
                    <h4>Realizamos un cumplimiento tributario conforme a la legislación nacional.</h4>
                  </div>
                  <ul>
                    <li>Realizar una evaluación crítica, desde el punto de vista tributario de las operaciones desarrolladas por la Sociedad, así como de la documentación de soporte de dichas operaciones por un período determinado, a la luz de la visión de SII.</li>
                    <li>Establecer el grado de cumplimiento de las obligaciones tributarias producto de las operaciones desarrolladas por la empresa y determinar si existe alguna desviación entre las prácticas seguidas por la empresa y las normas tributarias vigentes.</li>
                    <li>Identificar posibles riesgos tributarios relacionados con las operaciones de la sociedad o con la forma de documentar las mismas.</li>
                    <li>Brindar apoyo técnico para evaluar, conjuntamente con la gerencia de la empresa, aquellos aspectos que requieran la adopción de un cambio de criterio.</li>
                  </ul>
                  <p>Posteriormente, para continuar con un cumplimiento tributario conforme a la legislación nacional, nuestro servicio implica la tercerización de todas las tareas de cumplimiento tributario del día a día, por esta razón el equipo de impuestos de Contasi pone a su disposición un grupo de profesionales especialistas en impuestos que maximizarán las fortalezas de su departamento financiero y le brindarán soluciones de cumplimiento tributario a la medida de su organización y totalmente integradas.</p>
                </div>
              </div>
              <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="image">
                    <img src={ImgTributario} alt="empresa" />
                  </div>
                </div>
              </div>
            </div>
            <section className="call-to-action-three mt-4">
              <div className="auto-container">
                <div className="inner-container clearfix row">
                  <div className='col-8'>
                  <h3>¿Tienes problemas tributarios? Contasi te apoya</h3>
                  </div>
                  <div className='col-4'><a href='https://wa.me/56961922323' className="contact-btn" target='_blank' rel="noopener noreferrer">Envianos un Whatsapp Ahora <i class="fab fa-whatsapp"></i></a></div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Diagnostico
