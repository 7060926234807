import React, {
  Component,
  Fragment,
} from 'react';

import {
  ImgCreacionEmpresa,
  ImgCreacionEmpresa2,
} from '../../images/servicios';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class Empresa extends Component {

  render = () => {
    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo="Creación de Empresa" />
        <section className="welcome-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Somos Contasi</div>
                    <h2>CREACIÓN DE EMPRESA</h2>
                    <h4>No des este paso tan importante en tu emprendimiento sin una asesoría adecuada.</h4>
                  </div>
                  <h6>FORMALIZA TU EMPRESA</h6>
                  <p>Es importante constituir tu empresa, porque te permitirá separar el patrimonio de tu negocio del personal. Separarás tus finanzas de las de la empresa y gozarás de una limitación de responsabilidad hasta el límite de tu aporte.</p>
                  <h6>ASOCIARTE CON OTRAS PERSONAS</h6>
                  <p>Una empresa te permite asociarte con otras personas. En esta asociación se establecen cuestiones tan importantes, como por ejemplo de que forma van a participar cada uno en las utilidades y pérdidas de la empresa.</p>
                  <h6>CAPTAR INVERSIÓN</h6>
                  <p>La mayoría de las veces, las empresas para comenzar a funcionar requieren de grandes sumas de dinero antes de comenzar a producir.</p>
                  <h6>SERIEDAD A TU NEGOCIO</h6>
                  <p>Tus clientes y proveedores miraran con buenos ojos estar realizando negocios con una empresa y no con una persona natural, ya que normalmente las empresas tienen estructuras complejas y distintos colaboradores trabajando en ellas.</p>
                  <h6>PRODUCTOS BANCARIOS PARA TU EMPRESA</h6>
                  <p>Con tu empresa podrás ir obteniendo distintos productos bancarios como cuentas a la vista, cuentas corriente empresas, créditos, capitales de trabajo, todo según el comportamiento crediticio y movimientos de la empresa.</p>
                  <h6>FONDOS CONCURSABLES O INCENTIVOS</h6>
                  <p>El estado tiene una serie de programas pensados y orientados a la formalización de empresas, toda vez que estas generan nuevas fuentes de trabajo y nuevas fuentes de recaudación de impuestos.</p>
                </div>
              </div>
              <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="image">
                    <img src={ImgCreacionEmpresa} alt="empresa" />
                    <br />
                    <img src={ImgCreacionEmpresa2} alt="empresa" />
                  </div>
                </div>
              </div>
            </div>
            <section className="call-to-action-three mt-4">
              <div className="auto-container">
                <div className="inner-container clearfix row">
                  <div className='col-8'>
                    <h3>¿Necesitas crear una empresa? Aquí somos expertos</h3>
                  </div>
                  <div className='col-4'><a href='https://wa.me/56961922323' className="contact-btn" target='_blank' rel="noopener noreferrer">Envianos un Whatsapp Ahora <i class="fab fa-whatsapp"></i></a></div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Empresa;
