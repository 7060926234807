import React, {
  Component,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import ImgBgHome from '../../images/bg-home.jpg';
import {
  ImgBlendingcoffee,
  ImgCL,
  ImgComercialDiaz,
  ImgConforma,
  ImgFrutasbosquemar,
  ImgImperiodeleones,
  ImgInfocontrol,
  ImgMedisan,
  ImgMetalfort,
  ImgProinspecta,
  ImgQuintabogados,
  ImgRodotour,
  ImgStartCode,
  ImgTectronix,
  ImgTransporteMJ,
  ImgVidrioalum
} from '../../images/clientes';
import {
  ImgAsesoriasContables,
  ImgAuditoriaComunidades,
  ImgCreacionEmpresa,
  ImgRenta2022,
  ImgTributario,
} from '../../images/servicios';
import {
  guardarPostID,
  obtenerPosts,
} from '../../redux/actions/cms/action';
import {
  Footer,
  Header,
} from '../general';

export class Home extends Component {

  componentDidMount = () => {
    const { obtenerPosts } = this.props;
    obtenerPosts();
  }
  setPostID = id => {
    const { guardarPostID } = this.props;
    guardarPostID(id)
  }

  render = () => {
    const { posts } = this.props;
    const createMarkup = htmlString => ({ __html: htmlString });
    var url_api = process.env.REACT_APP_API_URL;

    return (
      <Fragment>
        <Header />
        <section className="main-slider">
          <img alt="foto" src={ImgBgHome} />
          <div className="titulo-home">Bienvenidos a Contasi</div>
          <div className="titulo-home t-2">Somos especialistas y te asesoramos en Contabilidades, Planificación Tributaria y Auditorias<br/>Planificación Renta 2025</div>
          <div className="btn-home">
            <a href='#nuestros_servicios' className="theme-btn btn-style-four">Nuestros Servicios</a>
            <a href='https://wa.me/56961922323' className="theme-btn btn-style-four">Contactanos por Whatsapp Ahora</a>
          </div>
        </section>
        <section className="services-section" id="nuestros_servicios">
          <div className="auto-container">
            <div className="sec-title">
              <h2>Nuestros Servicios</h2>
            </div>
            <div className="row">
              <div className="services-block-two col-lg-4">
                <div className="inner-box">
                  <div className="image">
                    <img className="img-home-servicios" src={ImgRenta2022} alt="renta" />
                    <div className="icon-box">
                      <span className="fa fa-search-dollar"></span>
                    </div>
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <div className="overlay-icon">
                            <span className="fa fa-search-dollar"></span>
                          </div>
                          <div className="text">Nos encargaremos de recomendar todos los beneficios tributarios aplicables a tu empresa.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lower-box">
                    <h3><Link to="/servicios/planificacion-renta-2025">Planificación de Renta 2025</Link></h3>
                    <Link to="/servicios/planificacion-renta-2025" className="arrow-box"><span className="fa fa-angle-right"></span></Link>
                  </div>
                </div>
              </div>
              <div className="services-block-two col-lg-4">
                <div className="inner-box">
                  <div className="image">
                    <img className="img-home-servicios" src={ImgAsesoriasContables} alt="asesorias" />
                    <div className="icon-box">
                      <span className="fa fa-balance-scale"></span>
                    </div>
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <div className="overlay-icon">
                            <span className="fa fa-balance-scale"></span>
                          </div>
                          <div className="text">Somos especialistas en Asesoría Tributaría, Laboral, Contable y Legal.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lower-box">
                    <h3><Link to="/servicios/asesorias-contables">Asesoría Contable</Link></h3>
                    <Link to="/servicios/asesorias-contables" className="arrow-box"><span className="fa fa-angle-right"></span></Link>
                  </div>
                </div>
              </div>
              <div className="services-block-two col-lg-4">
                <div className="inner-box">
                  <div className="image">
                    <img className="img-home-servicios" src={ImgAuditoriaComunidades} alt="asesorias" />
                    <div className="icon-box">
                      <span className="fa fa-users"></span>
                    </div>
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <div className="overlay-icon">
                            <span className="fa fa-users"></span>
                          </div>
                          <div className="text">Especialistas en Edificios y Condominios.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lower-box">
                    <h3><Link to="/servicios/auditoria-comunidades">Auditoria de Comunidades</Link></h3>
                    <Link to="/servicios/auditoria-comunidades" className="arrow-box"><span className="fa fa-angle-right"></span></Link>
                  </div>
                </div>
              </div>
              <div className="services-block-two col-lg-4">
                <div className="inner-box">
                  <div className="image">
                    <img className="img-home-servicios" src={ImgCreacionEmpresa} alt="asesorias" />
                    <div className="icon-box">
                      <span className="fa fa-user-tie"></span>
                    </div>
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <div className="overlay-icon">
                            <span className="fa fa-user-tie"></span>
                          </div>
                          <div className="text">Es importante constituir tu empresa, porque te permitirá separar el patrimonio de tu negocio del personal.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lower-box">
                    <h3><Link to="/servicios/creacion-empresa">Creación de Empresa</Link></h3>
                    <Link to="/servicios/creacion-empresa" className="arrow-box"><span className="fa fa-angle-right"></span></Link>
                  </div>
                </div>
              </div>
              <div className="services-block-two col-lg-4">
                <div className="inner-box">
                  <div className="image">
                    <img className="img-home-servicios" src={ImgTributario} alt="tributario" />
                    <div className="icon-box">
                      <span className="fa fa-dollar-sign"></span>
                    </div>
                    <div className="overlay-box">
                      <div className="overlay-inner">
                        <div className="content">
                          <div className="overlay-icon">
                            <span className="fa fa-dollar-sign"></span>
                          </div>
                          <div className="text">Realizamos un cumplimiento tributario conforme a la legislación nacional.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lower-box">
                    <h3><Link to="/servicios/diagnostico-tributario">Diagnóstico tributario</Link></h3>
                    <Link to="/servicios/diagnostico-tributario" className="arrow-box"><span className="fa fa-angle-right"></span></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="invest-section bg-mensaje">
          <div className="auto-container">
            <div className="content-box">
              <div className="icon-box">
                <span className="fa fa-thumbs-up"></span>
              </div>
              <h2>Somos especialistas en Asesoría Tributaría, Laboral, Contable y Legal</h2>
              <div className="text">Damos nuestros servicios a una amplia gama de empresas de diversos mercados y sectores económicos nacionales como internacionales.</div>
              <a href='#nuestros_servicios' className="theme-btn btn-style-six">Nuestros Servicios</a>
            </div>
          </div>
        </section>
        <section className="news-section-two">
          <div className="auto-container">
            <div className="sec-title">
              <div className="title">Nuestro blog</div>
              <h2>Últimos posts</h2>
            </div>
            <div className="row">
              {posts && posts.length > 0 ? posts.filter(post => post.is_destacado === true).map((post, x) => (
                <div className="news-block col-lg-4 col-md-6 col-sm-12" key={x}>
                  <div className="inner-box">
                    <div className="image">
                      {post.foto_nombre ?
                        <img src={`${url_api}/static/blog/${post.foto_nombre}`} alt="foto" />
                        : null}
                      <div className="post-date"><span>{post.text_fecha_dia}</span> {post.text_fecha_mes}</div>
                    </div>
                    <div className="lower-content">
                      <h3><Link onClick={this.setPostID.bind(this, post.id)} to={`/blog/${post.slug}`}>{post.titulo}</Link></h3>
                      <div dangerouslySetInnerHTML={createMarkup(post.extracto)} />
                    </div>
                  </div>
                </div>
              ))
                : null}
            </div>
          </div>
        </section>
        <section className="clients-section message-section bg-secondary">
          <div className="auto-container">
            <div className="sponsors-outer">
              <h3 className="text-center mb-4 text-white text-uppercase">Clientes que confiaron en nosotros</h3>
              <ul className="sponsors-carousel row text-center">
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgCL} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgStartCode} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgProinspecta} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgInfocontrol} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgMedisan} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgConforma} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgComercialDiaz} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgTectronix} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgMetalfort} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgVidrioalum} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgQuintabogados} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgBlendingcoffee} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-1 col-sm-11 col-4"><figure className="image-box"><img src={ImgImperiodeleones} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-2 col-sm-2 col-4"><figure className="image-box"><img src={ImgRodotour} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-1 col-sm-1 col-4"><figure className="image-box"><img src={ImgFrutasbosquemar} alt="cliente" /></figure></li>
                <li className="slide-item col-lg-1 col-sm-1 col-4"><figure className="image-box"><img src={ImgTransporteMJ} alt="cliente" /></figure></li>
              </ul>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

Home.propTypes = {
  obtenerPosts: PropTypes.func.isRequired,
  guardarPostID: PropTypes.func.isRequired,
  posts: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  posts: state.cms.posts,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { obtenerPosts, guardarPostID },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
