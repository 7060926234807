import axios from 'axios';

import {
  AUTENTIFICACION_FALLIDO,
  CERRAR_SESION_CORRECTO,
  CONTRASENA_FALLIDO,
  CONTRASENA_NUEVA,
  CONTRASENATOKEN_FALLIDO,
  CONTRASENATOKEN_NUEVA,
  INICIO_SESION_CORRECTO,
  INICIO_SESION_FALLIDO,
  MENSAJES_LIMPIAR,
  PEDIDOS_FALLIDO,
  PEDIDOS_OBTENER,
  REGISTRO_CORRECTO,
  REGISTRO_FALLIDO,
  TOKEN_CORRECTO,
  TOKEN_FALLIDO,
  USUARIO_ACTUALIZACION_FALLIDO,
  USUARIO_ACTUALIZACION_OBTENIDO,
  USUARIO_CARGADO,
  USUARIO_CARGANDO,
} from './types';

var url_api = process.env.REACT_APP_API_URL;
const config = {
  headers: {
    "Content-Type": "application/json"
  }
};

export const cargarUsuario = () => (dispatch, getState) => {
  dispatch({ type: USUARIO_CARGANDO });
  axios
    .get(`${url_api}/api/users/usuario`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: USUARIO_CARGADO,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: AUTENTIFICACION_FALLIDO,
        payload: err.response
      });
    });
};

export const iniciarSesion = (email, password) => dispatch => {
  const body = JSON.stringify({ email, password });
  axios
    .post(`${url_api}/api/users/inicio-sesion`, body, config)
    .then(res => {
      dispatch({
        type: INICIO_SESION_CORRECTO,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: INICIO_SESION_FALLIDO,
        payload: err.response,
      });
    });
};

export const validarToken = data => dispatch => {
  axios
    .post(`${url_api}/api/users/validar-token`, data, config)
    .then(res => {
      dispatch({
        type: TOKEN_CORRECTO,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: TOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const nuevaContrasenaToken = data => dispatch => {
  axios
    .post(`${url_api}/api/users/nueva-contrasena-token`, data, config)
    .then(res => {
      dispatch({
        type: CONTRASENATOKEN_NUEVA,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: CONTRASENATOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const nuevaContrasena = data => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/users/nueva-contrasena`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: CONTRASENA_NUEVA,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: CONTRASENA_FALLIDO,
        payload: err.response
      });
    });
};

export const cambioContrasena = (email) => dispatch => {
  axios
    .post(`${url_api}/api/users/recuperar-contrasena`, { 'email': email }, config)
    .then(res => {
      dispatch({
        type: CONTRASENATOKEN_NUEVA,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: CONTRASENATOKEN_FALLIDO,
        payload: err.response,
      });
    });
};

export const registro = data => dispatch => {
  axios
    .put(`${url_api}/api/users/registro`, data, config)
    .then(res => {
      dispatch({
        type: REGISTRO_CORRECTO,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: REGISTRO_FALLIDO,
        payload: err.response
      });
    });
};

export const cerrarSesion = () => (dispatch, getState) => {
  axios
    .post(`${url_api}/api/users/cerrar-sesion`, tokenConfig(getState))
    .then(res => {
      dispatch({ type: MENSAJES_LIMPIAR });
      dispatch({ type: CERRAR_SESION_CORRECTO });
    })
    .catch(err => {
      dispatch({
        type: INICIO_SESION_FALLIDO,
        payload: err.response
      });
    });
};

export const tokenConfig = getState => {
  const token = getState().auth.token;
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }
  return config;
};

export const limpiarMensajes = () => (dispatch) => {
  dispatch({
    type: MENSAJES_LIMPIAR
  });
};

export const obtenerPedidos = () => (dispatch, getState) => {
  axios
    .get(`${url_api}/api/store/pedidos`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: PEDIDOS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: PEDIDOS_FALLIDO,
        payload: err.response,
      });
    });
};

export const actualizacionUsuario = data => (dispatch, getState) => {
  axios
    .put(`${url_api}/api/users/actualizacion`, data, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: USUARIO_ACTUALIZACION_OBTENIDO,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: USUARIO_ACTUALIZACION_FALLIDO,
        payload: err.response,
      });
    });
};
