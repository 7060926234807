export const SERVICIOS_OBTENER = 'SERVICIOS_OBTENER';
export const SERVICIOS_FALLIDO = 'SERVICIOS_FALLIDO';
export const MENSAJES_LIMPIAR = 'MENSAJES_LIMPIAR';
export const CARRO_OBTENER = 'CARRO_OBTENER';
export const CARRO_FALLIDO = 'CARRO_FALLIDO';
export const SERVICIO_SELECCIONADO = 'SERVICIO_SELECCIONADO';
export const HORAS_DISPONIBLES_OBTENER = 'HORAS_DISPONIBLES_OBTENER';
export const HORAS_DISPONIBLES_FALLIDO = 'HORAS_DISPONIBLES_FALLIDO';
export const SERVICIO_OBTENER = 'SERVICIO_OBTENER';
export const SERVICIO_FALLIDO = 'SERVICIO_FALLIDO';
