import React, {
  Component,
  Fragment,
} from 'react';

import { Link } from 'react-router-dom';

import ivanb from '../../images/ivanb.jpg';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class Empresa extends Component {

  render = () => {

    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo="Empresa" />
        <section className="welcome-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-column col-lg-12 col-md-12 col-sm-12 mb-0">
                <div className="inner-column">
                  <div className="sec-title pb-3">
                    <div className="title">SOMOS CONTASI</div>
                    <h2>Dedicados al soporte de pymes</h2>
                  </div>
                  <div className="text mb-5">Debido a los constantes cambios en el mercado, entre los cuales las personas naturales, pequeñas y medianas empresas también llamadas Pymes requieren cada vez más un apoyo y soporte en asesorías tributarias y servicios contables. Contasi, empresa dedicada al soporte de las Pymes, brindando soluciones constantes para que cada Pyme se ocupe en aumentar cada vez más su productividad y por ende su rentabilidad, dejando en manos expertas su área financiera.</div>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="content-column col-lg-8 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <h2>Gerente General</h2>
                    <h4>Ivan Bahamonde</h4>
                  </div>
                  <div className="text mb-0">Ingeniero en Administración de Empresas, Diplomados en Gestión Contable y financiera, Diplomado en Negocios y Administración de Empresas Universidad Adolfo Ibáñez, MBA en Economía y Finanzas Universidad Finis Terrae, académico de diversas entidades de enseñanza tales como Universidad de las Américas, Inacap, Instituto de Estudios Bancarios Guillermo Subercaseaux entre otros, con amplia experiencia en Auditorias contables y Financieras, Asesorías tributarias y Contabilidades en general.</div>
                  <div className='text'>Junto a nuestro equipo de colaboradores nos encargamos de acompañarlos desde el comienzo de su empresa y apoyarlos en sus empresas en marcha de modo podamos en conjunto hacer crecer todos sus proyectos.</div>
                  <div className="clearfix">
                    <div className="pull-left">
                      <Link to="/contacto" className="theme-btn btn-style-four">Contacto</Link>
                    </div>
                    <div className="pull-right">
                      <div className="phone"><span className="icon fa fa-phone"></span> <a href="tel:+56961922323" target="_blank" rel="noopener noreferrer">+56 9 6192 2323</a></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="image-column col-lg-4 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="image">
                    <img src={ivanb} alt="ivanb" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Empresa;
