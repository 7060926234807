import React, { Component } from 'react';

// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import { bindActionCreators } from 'redux';
import ImgLogo from '../../images/logohb.png';

// import { cerrarSesion } from '../../redux/actions/auth/action';

export class Header extends Component {

  render = () => {
    // const { user, cerrarSesion } = this.props;
    return (
      <header className="main-header header-style-three">
        <div className="header-upper">
          <div className="auto-container clearfix">
            <div className="pull-left logo-outer">
              <div className="logo"><Link to="/"><img src={ImgLogo} alt="logo" width="250" /></Link></div>
            </div>
            <div className="pull-right upper-right">
              <div className="row clearfix">
                <div className="col-lg-4 col-sm-4 col-xs-4 info-box">
                  <div className="icon-box"><span className="fa fa-clock"></span></div>
                  <ul>
                    <li><strong>9:00 a 19:00 hrs</strong>De lunes a viernes</li>
                  </ul>
                </div>
                <div className="col-lg-4 col-sm-4 col-xs-4 info-box">
                  <div className="icon-box"><span className="fab fa-whatsapp"></span></div>
                  <ul>
                    <li><strong><a href="tel:+56961922323" target="_blank" rel="noopener noreferrer">+56 9 6192 2323</a></strong></li>
                  </ul>
                </div>
                <div className="col-lg-4 col-sm-3 col-xs-3 info-box">
                  <div className="icon-box"><span className="fa fa-envelope"></span></div>
                  <ul>
                    <li><strong><a target="_blank" rel="noopener noreferrer" href="mailto:contacto@contasi.cl">contacto@contasi.cl</a></strong></li>
                  </ul>
                </div>
              </div>
              {/* <ul className="header-info-list">
                {user && Object.keys(user).length > 0 ?
                  <Fragment>
                    <li>
                      <span><i className="fa fa-star"></i>Hola {user.perfil.primer_nombre}!</span>
                    </li>
                    <li>
                      <Link className="text-white" to="/mis-datos">Mis Datos <i className="fa fa-user"></i></Link>
                    </li>
                    <li>
                      <Link className="text-white" to="/mis-pedidos">Mis Pedidos <i className="fa fa-shopping-bag"></i></Link>
                    </li>
                    <li>
                      <Link className="text-white" to="/cambio-contrasena">Cambio Contraseña <i className="fa fa-lock"></i></Link>
                    </li>
                    <li>
                      <span onClick={cerrarSesion}>Cerrar Sesión <i className="fa fa-sign-out-alt"></i></span>
                    </li>
                  </Fragment>
                  :
                  <Fragment>
                    <li>
                      <Link className="text-white" to="/inicio-sesion">Inicio de Sesión <i className="fa fa-sign-in-alt"></i></Link>
                    </li>
                    <li>
                      <Link className="text-white" to="/crear-cuenta">Crear Cuenta <i className="fa fa-user"></i></Link>
                    </li>
                    <li>
                      <Link className="text-white" to="/recuperar-contrasena">Recuperar Contraseña <i className="fa fa-key"></i></Link>
                    </li>
                  </Fragment>
                }
                <li className="social">
                  <span><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/contasi"><span className="fab fa-facebook"></span></a></span>
                  <span><a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com/contasi"><span className="fab fa-twitter"></span></a></span>
                  <span><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/contasi"><span className="fab fa-instagram"></span></a></span>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="header-lower clearfix">
          <div className="auto-container">
            <div className="nav-outer clearfix">
              <nav className="main-menu navbar-expand-md">
                <div className="navbar-header">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                  <ul className="navigation clearfix">
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link to="/empresa">Empresa</Link></li>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/contacto">Contacto</Link></li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

/* Header.propTypes = {
  user: PropTypes.object.isRequired,
  cerrarSesion: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { cerrarSesion },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header); */

export default Header;
