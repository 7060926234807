import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  guardarPostID,
  listadoTags,
  obtenerPosts,
} from '../../redux/actions/cms/action';

var url_api = process.env.REACT_APP_API_URL;

export class Sidebar extends Component {

  componentDidMount = () => {
    const { obtenerPosts, listadoTags, posts, categorias } = this.props;
    if (posts.length === 0) {
      obtenerPosts();
    }
    if (categorias.length === 0) {
      listadoTags();
    }
  }

  setPostID = id => {
    const { guardarPostID } = this.props;
    guardarPostID(id)
  }

  render = () => {
    const { posts, categorias } = this.props;
    const destacadas = posts.filter(post => post.is_destacado === true).slice(0, 4);
    const ultimas = posts.slice(0, 4);

    return (
      <div className="sidebar-side col-lg-3 col-md-12 mb-res-md-60px mb-res-sm-60px">
        <section className="sidebar-page-container sdbconta">
          <aside className="sidebar default-sidebar">
            <div className="sidebar-widget popular-posts">
              <div className="sidebar-title"><h2>Últimas Noticias</h2></div>
              {ultimas.map((post, x) => (
                <article className="post" key={x}>
                  <figure className="post-thumb">
                    {post.foto_nombre ?
                      <img src={`${url_api}/static/blog/${post.foto_nombre}`} alt="foto blog" />
                      : null}
                    <Link onClick={this.setPostID.bind(this, post.id)} to={`/blog/${post.slug}`} className="overlay-box"><span className="icon fa fa-link"></span></Link>
                  </figure>
                  <div className="text">
                    <Link onClick={this.setPostID.bind(this, post.id)} to={`/blog/${post.slug}`}>{post.titulo}</Link>
                  </div>
                  <div className="post-info">{post.text_fecha_dia} {post.text_fecha_mes}, {post.text_fecha_anio}</div>
                </article>
              ))}
            </div>
            <div className="sidebar-widget popular-posts">
              <div className="sidebar-title"><h2>Últimas Destacadas</h2></div>
              {destacadas.map((post, y) => (
                <article className="post" key={y}>
                  <figure className="post-thumb">
                    {post.foto_nombre ?
                      <img src={`${url_api}/static/blog/${post.foto_nombre}`} alt="foto blog" />
                      : null}
                    <Link to={`/blog/${post.slug}`} onClick={this.setPostID.bind(this, post.id)} className="overlay-box"><span className="icon fa fa-link"></span></Link>
                  </figure>
                  <div className="text">
                    <Link onClick={this.setPostID.bind(this, post.id)} to={`/blog/${post.slug}`}>{post.titulo}</Link>
                  </div>
                  <div className="post-info">{post.text_fecha_dia} {post.text_fecha_mes}, {post.text_fecha_anio}</div>
                </article>
              ))}
            </div>
            <div className="sidebar-widget popular-tags">
              <div className="sidebar-title">
                <h2>Tag Cloud</h2>
              </div>
              {categorias && categorias.length > 0 ? categorias.map((categoria, z) => (
                <li key={z}><Link to={`/blog/categoria/${categoria.slug}`}>{categoria.nombre}</Link></li>
              ))
                : null}
            </div>
          </aside>
        </section>
      </div>
    );
  }
}

Sidebar.propTypes = {
  obtenerPosts: PropTypes.func.isRequired,
  listadoTags: PropTypes.func.isRequired,
  guardarPostID: PropTypes.func.isRequired,
  categorias: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  categorias: state.cms.categorias,
  posts: state.cms.posts,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      obtenerPosts, listadoTags, guardarPostID
    },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
