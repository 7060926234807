export const POSTS_OBTENER = 'POSTS_OBTENER';
export const POSTS_FALLIDO = 'POSTS_FALLIDO';
export const CATEGORIAS_OBTENER = 'CATEGORIAS_OBTENER';
export const CATEGORIAS_FALLIDO = 'CATEGORIAS_FALLIDO';
export const POST_OBTENER = 'POST_OBTENER';
export const POST_FALLIDO = 'POST_FALLIDO';
export const POST_CATEGORIA_OBTENER = 'POST_CATEGORIA_OBTENER';
export const POST_CATEGORIA_FALLIDO = 'POST_CATEGORIA_FALLIDO';
export const POST_ID_GUARDAR = 'POST_ID_GUARDAR';
export const POST_ID_LIMPIAR = 'POST_ID_LIMPIAR';
