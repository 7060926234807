import React, { Component } from 'react';

import { Link } from 'react-router-dom';

export class Breadcrumb extends Component {
  render = () => {
    const { titulo } = this.props;
    return (
      <section className="page-title bg-home">
        <div className="auto-container">
          <h1>{titulo}</h1>
          <ul className="page-breadcrumb">
            <li><Link to="/">Inicio</Link></li>
            <li>{titulo}</li>
          </ul>
        </div>
      </section>
    );
  }
}

export default Breadcrumb;
