import React, {
  Component,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Link,
  Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SweetAlert from 'sweetalert2';

import {
  iniciarSesion,
  limpiarMensajes,
} from '../../redux/actions/auth/action';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class InicioSesion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  }

  onSubmit = e => {
    e.preventDefault();
    const { iniciarSesion } = this.props;
    iniciarSesion(this.state.email, this.state.password);
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { is_authenticated, limpiarMensajes, errores } = this.props;
    const { email, password } = this.state;
    if (is_authenticated) {
      return <Redirect to="/" />;
    }

    if (errores && Object.entries(errores).length > 0 &&
      Object.entries(errores.data).length > 0 &&
      errores.data.inicio_sesion &&
      Object.entries(errores.data.inicio_sesion).length > 0) {
      limpiarMensajes();
      var error = "";
      for (const err in errores.data.inicio_sesion) {
        error = error + `<h5>${errores.data.inicio_sesion[err]}</h5>`;
      }
      SweetAlert.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        title: 'Inicio de sesión',
        html: error,
        icon: 'error',
        confirmButtonText: 'Volver a intentar <i className="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo={'Inicio de Sesión'} />
        <section className="login-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="column col-lg-12 col-md-12 col-sm-12 text-center">
                <h4>Para comprar un servicio de Contasi tienes que tener una cuenta creada</h4>
                <h5>Si no tienes una, <Link to="/crear-cuenta">crea una aquí</Link> y sigues los pasos</h5>
                <h5>De lo contrario, si ya tienes una cuenta, inicia sesión en el recuadro inferior.</h5>
              </div>
              <div className="column col-lg-6 col-md-12 col-sm-12 mt-5">
                <h2>Iniciar sesión en Contasi</h2>
                <div className="login-form">
                  <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <label>Email <span className="text-danger">*</span></label>
                      <input type="email" name="email" placeholder="Ingresa su email" onChange={this.onChange} value={email} className={errores && errores.data && errores.data.email ? 'form-control is-invalid' : null} />
                      {errores && errores.data && errores.data.email ?
                        errores.data.email.map((err, x) => (
                          <div key={x} className="invalid-feedback">{err}</div>
                        ))
                        : null}
                    </div>
                    <div className="form-group">
                      <label>Contraseña <span className="text-danger">*</span></label>
                      <input type="password" name="password" placeholder="Ingrese su contraseña" onChange={this.onChange} value={password} className={errores && errores.data && errores.data.password ? 'form-control is-invalid' : null} />
                      {errores && errores.data && errores.data.password ?
                        errores.data.password.map((err, x) => (
                          <div key={x} className="invalid-feedback">{err}</div>
                        ))
                        : null}
                    </div>
                    <div className="clearfix">
                      <div className="pull-right">
                        <div className="form-group no-margin">
                          <button className="theme-btn btn-style-one" type="submit">Iniciar sesión <i className="fas fa-sign-in-alt ml-1"></i></button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <Link to="/recuperar-contrasena">¿Necesitas recuperar tu contraseña?</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment >
    );
  }
}

InicioSesion.propTypes = {
  iniciarSesion: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores: PropTypes.object,
};

const mapStateToProps = state => ({
  is_authenticated: state.auth.is_authenticated,
  errores: state.auth.errores,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { iniciarSesion, limpiarMensajes },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InicioSesion);
