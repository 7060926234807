import React, {
  Component,
  Fragment,
} from 'react';

import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class Contacto extends Component {

  render = () => {

    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo="Contacto" />
        <section className="contact-info-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                  <li>
                    <span className="icon fa fa-phone-alt"></span>
                     <a target="_blank" rel="noopener noreferrer" href="mailto:contacto@contasi.cl">contacto@contasi.cl</a>
                  </li>
                </ul>
              </div>
              <div className="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                  <li>
                    <span className="icon fa fa-paper-plane"></span>
                    <a href="tel:+56961922323" target="_blank" rel="noopener noreferrer">+56 9 6192 2323</a> / <a href="tel:+323468722" target="_blank" rel="noopener noreferrer">32-3468722</a>
                  </li>
                </ul>
              </div>
              <div className="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                  <li>
                    <span className="icon fa fa-clock"></span>
                    Lunes a Viernes: 9:00 a 19:00 hrs <br />
                    Sabado / Domingos y Festivos Cerrado
                  </li>
                </ul>
              </div>
              <div className="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                  <li>
                    <span className="icon fa fa-map"></span>
                    Oficina Santiago Centro: Av. Libertador Bernardo O'Higgins 1302, Of. 70
                  </li>
                </ul>
                <div className="embed-responsive embed-responsive-16by9 mt-5">
                  <iframe title="mapa contacto" className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.1564610478704!2d-70.654338!3d-33.445229999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c5a7988a4645%3A0x7dc3122dc08c133b!2sAv.%20Libertador%20Bernardo%20O&#39;Higgins%201302%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1683141176409!5m2!1sen!2scl" allowFullScreen></iframe>
                </div>
              </div>
              <div className="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                  <li>
                    <span className="icon fa fa-map"></span>
                    Oficina Providencia: Av Providencia 1208, Of. 207
                  </li>
                </ul>
                <div className="embed-responsive embed-responsive-16by9 mt-5">
                  <iframe title="mapa contacto" className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.7389200014695!2d-70.6205873!3d-33.430050400000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf62b3781077%3A0x806a1a05f49d512!2sAv.%20Providencia%201208%2C%207500571%20Providencia%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1683141209737!5m2!1sen!2scl" allowFullScreen></iframe>
                </div>
              </div>
              <div className="column col-lg-4 col-md-6 col-sm-12">
                <ul>
                  <li>
                    <span className="icon fa fa-map"></span>
                    Oficina Viña del Mar: 12 Norte 785, Of. 406
                  </li>
                </ul>
                <div className="embed-responsive embed-responsive-16by9 mt-5">
                  <iframe title="mapa contacto" className="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.7267056799105!2d-71.54809999999999!3d-33.010979999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9689ddc29bb2cb25%3A0x4c498f37f9340793!2s12%20Nte.%20785%2C%202531172%20Valpara%C3%ADso%2C%20Vi%C3%B1a%20del%20Mar%2C%20Valpara%C3%ADso!5e0!3m2!1sen!2scl!4v1683141230837!5m2!1sen!2scl" allowFullScreen></iframe>
                </div>
              </div>
              
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Contacto;
