import axios from 'axios';

import {
  CATEGORIAS_FALLIDO,
  CATEGORIAS_OBTENER,
  POST_CATEGORIA_FALLIDO,
  POST_CATEGORIA_OBTENER,
  POST_FALLIDO,
  POST_ID_GUARDAR,
  POST_ID_LIMPIAR,
  POST_OBTENER,
  POSTS_FALLIDO,
  POSTS_OBTENER,
} from './types';

var url_api = process.env.REACT_APP_API_URL;

export const guardarPostID = id => (dispatch) => {
  dispatch({ type: POST_ID_LIMPIAR });
  dispatch({
    type: POST_ID_GUARDAR,
    payload: id,
  });
};

export const obtenerPosts = () => (dispatch) => {
  axios
    .get(`${url_api}/api/cms/blog`)
    .then(res => {
      dispatch({
        type: POSTS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: POSTS_FALLIDO,
        payload: error
      });
    });
};

export const listadoPostCategoria = slug => (dispatch) => {
  axios
    .get(`${url_api}/api/cms/tag/${slug}`)
    .then(res => {
      dispatch({
        type: POST_CATEGORIA_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: POST_CATEGORIA_FALLIDO,
        payload: error
      });
    });
};

export const obtenerPost = slug => (dispatch) => {
  axios
    .get(`${url_api}/api/cms/post/${slug}`)
    .then(res => {
      dispatch({
        type: POST_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: POST_FALLIDO,
        payload: error
      });
    });
};

export const listadoTags = () => (dispatch) => {
  axios
    .get(`${url_api}/api/cms/tags`)
    .then(res => {
      dispatch({
        type: CATEGORIAS_OBTENER,
        payload: res.data
      });
    })
    .catch(err => {
      var error = err.response;
      dispatch({
        type: CATEGORIAS_FALLIDO,
        payload: error
      });
    });
};
