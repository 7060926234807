import React, {
  Component,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Link,
  Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SweetAlert from 'sweetalert2';

import {
  cambioContrasena,
  limpiarMensajes,
} from '../../redux/actions/auth/action';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class RecuperarContrasena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading_guardar: '',
    };
  }

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  }

  onClickRecuperarContrasena = e => {
    const { cambioContrasena } = this.props;
    const { email } = this.state;
    var loading = SweetAlert.fire({
      title: 'Recuperar Contraseña',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      text: 'Validando la información ingresada, espere unos segundos',
      icon: 'info',
      showConfirmButton: false,
      onBeforeOpen: () => {
        SweetAlert.showLoading()
      },
    });
    this.setState({
      loading_guardar: loading,
    });
    cambioContrasena(email);
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { is_authenticated, errores_pass, guardar_pass, limpiarMensajes } = this.props;
    const { email, loading_guardar } = this.state;
    if (is_authenticated) {
      return <Redirect to="/" />;
    }
    if (Object.entries(guardar_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: 'Recuperar contraseña',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: guardar_pass.message,
        icon: 'success',
        confirmButtonText: 'Cerrar <i className="fa fa-thumbs-up"></i>',
        customClass: {
          confirmButton: 'btn btn-primary mr-3',
        },
        buttonsStyling: false,
      });
    }
    if (Object.entries(errores_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: 'Recuperar contraseña',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: errores_pass.data.message,
        icon: 'error',
        confirmButtonText: 'Cerrar <i className="fa fa-angle-right"></i>',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      });
    }
    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo={'Recupera tu contraseña'} />
        <section className="login-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="column col-lg-6 col-md-12 col-sm-12">
                <h2>Recupera tu contraseña</h2>
                <div className="login-form">
                  <h6>Ingresa tu email y recibirás un email para recuperar la contraseña de tu cuenta</h6>
                  <br />
                  <form>
                    <div className="form-group">
                      <label>Email <span className="text-danger">*</span></label>
                      <input type="email" name="email" placeholder="Ingresa su email" onChange={this.onChange} value={email} />
                    </div>
                    <div className="clearfix">
                      <div className="pull-right">
                        <div className="form-group no-margin">
                          <button className="theme-btn btn-style-one" type="button" onClick={this.onClickRecuperarContrasena}>Recuperar contraseña <i className="fas fa-paper-plane ml-1"></i></button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <Link to="/inicio-sesion"><i className="fa fa-angle-left"></i> Volver a iniciar sesión</Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

RecuperarContrasena.propTypes = {
  cambioContrasena: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores_pass: PropTypes.object,
  guardar_pass: PropTypes.object,
};

const mapStateToProps = state => ({
  is_authenticated: state.auth.is_authenticated,
  errores_pass: state.auth.errores_pass,
  guardar_pass: state.auth.guardar_pass,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { cambioContrasena, limpiarMensajes },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RecuperarContrasena);
