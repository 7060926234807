import {
  CATEGORIAS_FALLIDO,
  CATEGORIAS_OBTENER,
  POST_CATEGORIA_FALLIDO,
  POST_CATEGORIA_OBTENER,
  POST_FALLIDO,
  POST_ID_GUARDAR,
  POST_ID_LIMPIAR,
  POST_OBTENER,
  POSTS_FALLIDO,
  POSTS_OBTENER,
} from '../actions/cms/types';

const initialState = {
  posts: [],
  destacadas: [],
  ultimas: [],
  categorias: [],
  post: {},
  post_id: 0,
  categoria: {},
  errores: {},
};

export default function cms(state = initialState, action) {
  switch (action.type) {
    case POST_ID_GUARDAR:
      return {
        ...state,
        post_id: action.payload,
      };
    case POST_ID_LIMPIAR:
      return {
        ...state,
        post_id: 0,
      };
    case POSTS_OBTENER:
      return {
        ...state,
        posts: action.payload,
        post: {},
      };
    case POSTS_FALLIDO:
      return {
        ...state,
        posts: [],
      };
    case CATEGORIAS_OBTENER:
      return {
        ...state,
        categorias: action.payload,
      };
    case CATEGORIAS_FALLIDO:
      return {
        ...state,
        categorias: [],
      };
    case POST_OBTENER:
      return {
        ...state,
        post: action.payload,
        errores: {}
      };
    case POST_FALLIDO:
      return {
        ...state,
        errores: action.payload,
        post: {}
      };
    case POST_CATEGORIA_OBTENER:
      return {
        ...state,
        posts: action.payload.posts,
        categoria: action.payload.tag,
        errores: {}
      };
    case POST_CATEGORIA_FALLIDO:
      return {
        ...state,
        posts: [],
        categoria: {},
        errores: action.payload
      };
    default:
      return state;
  }
}
