import React, {
  Component,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Link,
  Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { obtenerPost } from '../../redux/actions/cms/action';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';
import Sidebar from './sidebar';

var url_api = process.env.REACT_APP_API_URL;

export class Post extends Component {
  componentDidMount = () => {
    const { obtenerPost, post_id } = this.props;
    if (post_id > 0) {
      obtenerPost(post_id);
    }
  }

  render = () => {
    const { post, post_id } = this.props;
    const createMarkup = htmlString => ({ __html: htmlString });
    if (post_id === 0) {
      return <Redirect to="/blog" />;
    }

    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo='Post' />
        <div className="sidebar-page-container">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-side col-lg-8 col-md-8 col-sm-12">
                <div className="blog-single">
                  <div className="inner-box">
                    <div className="image">
                      {post.foto_nombre ?
                        <img src={`${url_api}/static/blog/${post.foto_nombre}`} alt="foto blog" />
                        : null}
                      <div className="post-date"><span>{post.text_fecha_dia}</span>{post.text_fecha_mes}</div>
                    </div>
                    <h2>{post ? post.titulo : null}</h2>
                    <div className="text" dangerouslySetInnerHTML={createMarkup(post ? post.extracto : null)} />
                    <div className="text" dangerouslySetInnerHTML={createMarkup(post ? post.texto : null)} />
                    <p className="mt-4 text-right">
                      <Link to="/blog">Volver al listado <i className="fa fa-arrow-alt-circle-right"></i></Link>
                    </p>
                  </div>
                </div>
              </div>
              <Sidebar />
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

Post.propTypes = {
  obtenerPost: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  post_id: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  post: state.cms.post,
  post_id: state.cms.post_id,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      obtenerPost
    },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Post);
