import React, {
  Component,
  Fragment,
} from 'react';

import {
  ImgAuditoriaComunidades,
  ImgAuditoriaComunidades2,
} from '../../images/servicios';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class Auditoria extends Component {

  render = () => {
    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo="Auditoria de Comunidades" />
        <section className="welcome-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Somos Contasi</div>
                    <h2>AUDITORIA DE COMUNIDADES</h2>
                    <h4>A través del tiempo hemos podido optimizar nuestros procesos y con ello disminuir los costos de las auditorias sin sacrificar calidad.</h4>
                  </div>
                  <p>Somos un equipo de profesionales con basta experiencia en administración y asesoria de Comunidades y Condominios. Nos preocupamos por hacer un análisis administrativo integral con el fin de aportar tranquilidad sobre los procedimientos que el Administrador o Contador está llevando en su Comunidad.</p>
                  <h5 className='text-dark'>1. VERIFICACION</h5>
                  <p>Verificar la buena administración de fondos, recomendaciones y obligaciones ante el cumplimiento de normativas legales vigentes de la Ley de copropiedades.</p>
                  <h5 className='text-dark'>2. FINANZAS</h5>
                  <p>Revisar de manera minuciosa los ingresos y egresos, con sus respaldos y documentos legales correspondientes; factura, Boleta de Compraventa.</p>
                  <h5 className='text-dark'>3. ANÁLISIS BANCARIO</h5>
                  <p>Que permita conciliar los cargos bancarios con los documentos de egreso correspondientes y análisis del real estado de pago de los servicios básicos.</p>
                  <h5 className='text-dark'>4. TRIBUTARIA</h5>
                  <p>Dentro de este punto se abarca el pago de retenciones, impuestos únicos, (más declaración juradas correspondiente) y la declaración anual de la renta.</p>
                  <h5 className='text-dark'>5. LEYES LABORALES</h5>
                  <p>Todo lo que considera la obligación del condómino para sus trabajadores como; contratos, liquidaciones de sueldos, cotizaciones previsionales, feriados, horas extras y finiquitos.</p>
                  <h5 className='text-dark'>6. TRANSPARENCIA</h5>
                  <p>Se emite un informe especificando detalladamente el trabajo realizado, que servirá para dar claridad a la comunidad de que decisiones tomar.</p>
                </div>
              </div>
              <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="image">
                    <img src={ImgAuditoriaComunidades} alt="empresa" />
                    <br />
                    <img src={ImgAuditoriaComunidades2} alt="empresa" />
                  </div>
                </div>
              </div>
            </div>
            <section className="call-to-action-three mt-4">
              <div className="auto-container">
                <div className="inner-container clearfix row">
                  <div className='col-8'>
                    <h3>¿Auditoria de comunidades? Contasi es tu opción</h3>
                  </div>
                  <div className='col-4'><a href='https://wa.me/56961922323' className="contact-btn" target='_blank' rel="noopener noreferrer">Envianos un Whatsapp Ahora <i class="fab fa-whatsapp"></i></a></div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Auditoria
