import React from 'react';

import { connect } from 'react-redux';
import {
  Redirect,
  Route,
} from 'react-router-dom';

import logo from '../../images/logo.png';

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.is_loading) {
        return (
          <div className="d-flex justify-content-center mt-5">
            <div className="text-center">
              <img width={100} src={logo} alt="logo" />
              <h1>Contasi | Inicio</h1>
              <h2><i className="fas fa-spinner fa-pulse"></i></h2>
            </div>
          </div>
        )
      } else if (!auth.is_authenticated) {
        return <Redirect to="/inicio-sesion" />;
      } else {
        return <Component {...props} />;
      }
    }}
  />
);

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
