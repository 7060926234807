import React, {
  Component,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Link,
  Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SweetAlert from 'sweetalert2';

import {
  limpiarMensajes,
  nuevaContrasenaToken,
  validarToken,
} from '../../redux/actions/auth/action';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class NuevaContrasena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      uid: '',
      token: '',
      loading_guardar: '',
    };
  }

  componentDidMount = () => {
    const { validarToken } = this.props;
    const { uid, token } = this.props.match.params;
    validarToken({
      'uid': uid,
      'token': token,
    })
    this.setState({
      uid: uid,
      token: token,
    });
  }

  onClickNuevaContrasena = e => {
    const { nuevaContrasenaToken } = this.props;
    const { password1, password2, uid, token } = this.state;
    var loading = SweetAlert.fire({
      title: 'Nueva Contraseña',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      text: 'Validando la información ingresada, espere unos segundos',
      icon: 'info',
      showConfirmButton: false,
      onBeforeOpen: () => {
        SweetAlert.showLoading()
      },
    });
    this.setState({
      loading_guardar: loading,
    });
    nuevaContrasenaToken({
      'password1': password1,
      'password2': password2,
      'uid': uid,
      'token': token,
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { loading_guardar } = this.state;
    const { is_authenticated, is_token_valido, limpiarMensajes, guardar_pass, errores_pass } = this.props;
    if (is_authenticated) {
      return <Redirect to="/" />;
    }
    if (Object.entries(guardar_pass).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: 'Nueva contraseña',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: guardar_pass.message,
        icon: 'success',
        confirmButtonText: 'Iniciar Sesión <i className="fas fa-sign-in-alt"></i>',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.history.push('/inicio-sesion');
      })
    }
    if (Object.entries(errores_pass).length > 0) {
      loading_guardar.close();
      if (errores_pass.data && errores_pass.data.message) {
        limpiarMensajes();
        SweetAlert.fire({
          title: 'Nueva contraseña',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          text: errores_pass.data.message,
          icon: 'error',
          confirmButtonText: 'Cerrar <i className="fa fa-angle-right"></i>',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        });
      }
    }
    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo={'Crea tu nueva contraseña'} />
        <section className="login-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="column col-lg-6 col-md-12 col-sm-12">
                <h2>Crea tu nueva contraseña</h2>
                <div className="login-form">
                  <br />
                  <form>
                    {is_token_valido ?
                      <form>
                        <div className="form-group">
                          <label>Contraseña</label>
                          <input type="password" name="password1" placeholder="Ingresa su nueva contraseña" onChange={this.onChange} className={errores_pass && errores_pass.data && errores_pass.data.password1 ? 'is-invalid' : null} />
                          {errores_pass && errores_pass.data && errores_pass.data.password1 ?
                            errores_pass.data.password1.map((err, x) => (
                              <div key={x} className="invalid-feedback">{err}</div>
                            ))
                            : null}
                        </div>
                        <div className="form-group">
                          <label>Repita la contraseña</label>
                          <input type="password" name="password2" placeholder="Repita su nueva contraseña" onChange={this.onChange} className={errores_pass && errores_pass.data && errores_pass.data.password2 ? 'is-invalid' : null} />
                          {errores_pass && errores_pass.data && errores_pass.data.password2 ?
                            errores_pass.data.password2.map((err, x) => (
                              <div key={x} className="invalid-feedback">{err}</div>
                            ))
                            : null}
                        </div>
                        <div className="clearfix">
                          <div className="pull-right">
                            <div className="form-group no-margin">
                              <button className="theme-btn btn-style-one" type="button" onClick={this.onClickNuevaContrasena}>Guardar nueva contraseña <i className="fas fa-paper-plane ml-1"></i></button>
                            </div>
                          </div>
                        </div>
                      </form>
                      :
                      <div>
                        <h4 className="mb-2">Link para cambiar la contraseña no válido</h4>
                        <p>El Link que esta solicitando cambiar la contraseña expiró o no es válido.</p>
                        <p>Puedes solicitar nuevamente recuperar la contraseña en el siguiente botón:</p>
                        <div className="button-box mt-3">
                          <Link className="theme-btn btn-style-one" to="/recuperar-contrasena">Ingresa aquí</Link>
                        </div>
                      </div>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

NuevaContrasena.propTypes = {
  nuevaContrasenaToken: PropTypes.func.isRequired,
  validarToken: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores_pass: PropTypes.object,
  guardar_pass: PropTypes.object,
  is_token_valido: PropTypes.bool,
};

const mapStateToProps = state => ({
  is_authenticated: state.auth.is_authenticated,
  guardar_pass: state.auth.guardar_pass,
  errores_pass: state.auth.errores_pass,
  is_token_valido: state.auth.is_token_valido,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { nuevaContrasenaToken, validarToken, limpiarMensajes },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NuevaContrasena);
