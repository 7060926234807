import React, {
  Component,
  Fragment,
} from 'react';

import {
  ImgAsesoriasContables,
  ImgAsesoriasContables2,
} from '../../images/servicios';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class Asesoria extends Component {

  render = () => {
    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo="Asesorias Contables" />
        <section className="welcome-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="content-column col-lg-7 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <div className="title">Somos Contasi</div>
                    <h2>ASESORIAS CONTABLES</h2>
                    <h4>Somos especialistas en Asesoría Tributaría, Laboral, Contable y Legal.</h4>
                  </div>
                  <p>Damos nuestros servicios a una amplia gama de empresas de diversos mercados y sectores económicos nacionales como internacionales. Dentro de los cuales realizamos análisis Económicos-Financieros, Auditorías y asesoramientos específicos de gran complejidad que requieran un alto grado de análisis.</p>
                  <p>En Chile, el mantener una empresa ya sea grande o pequeña puede ser muy alto debido a los altos impuestos que se deben pagar mensual y anualmente al fisco. El IVA, el Impuesto a la Renta Anual, las distintas retenciones, y otros impuestos específicos pueden consumir en promedio hasta el 35% anual de las utilidades de las empresas. </p>
                  <p>Somos una empresa de contabilidad dinámica, con los recursos y elementos necesarios para la óptima ejecución de nuestras actividades y más de 20 años de experiencia en el mercado de la consultoría y asesoría contable, tributarias y laborales en Chile. Contamos con contadores profesionales interdisciplinarios y altamente capacitados, que entregan soluciones personalizadas e innovadoras.</p>
                </div>
              </div>
              <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="image">
                    <img src={ImgAsesoriasContables} alt="empresa" />
                    <br />
                    <img src={ImgAsesoriasContables2} alt="empresa" />
                  </div>
                </div>
              </div>
            </div>
            <section className="call-to-action-three mt-4">
              <div className="auto-container">
                <div className="inner-container clearfix row">
                  <div className='col-8'><h3>¿Necesitas una asesoria contable? Contasi te ayuda</h3></div>
                  <div className='col-4'><a href='https://wa.me/56961922323' className="contact-btn" target='_blank' rel="noopener noreferrer">Envianos un Whatsapp Ahora <i class="fab fa-whatsapp"></i></a></div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Asesoria;
