import React, {
  Component,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import {
  Link,
  Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SweetAlert from 'sweetalert2';

import {
  limpiarMensajes,
  registro,
} from '../../redux/actions/auth/action';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

export class Registro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primer_nombre: '',
      segundo_nombre: '',
      apellido_paterno: '',
      apellido_materno: '',
      fecha_nacimiento: '',
      genero: '',
      celular: '',
      email: '',
      password: '',
      loading_guardar: '',
    };
  }

  componentDidUpdate = (prevProps) => {
    const { registro_valido } = this.props;
    if (registro_valido.email !== prevProps.registro_valido.email) {
      this.setState({ email: registro_valido.email });
    }
  }

  componentWillUnmount = () => {
    const { limpiarMensajes } = this.props;
    limpiarMensajes();
  }

  onClickGuardarRegistro = () => {
    const { registro } = this.props;
    const { primer_nombre, segundo_nombre, apellido_paterno, apellido_materno,
      fecha_nacimiento, genero, email, celular, password } = this.state;
    var loading = SweetAlert.fire({
      title: 'Creación de la cuenta',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      text: 'Validando la información ingresada, espere unos segundos',
      icon: 'info',
      showConfirmButton: false,
      onBeforeOpen: () => {
        SweetAlert.showLoading()
      },
    });
    this.setState({
      loading_guardar: loading,
    });
    registro({
      'perfil': {
        'primer_nombre': primer_nombre ? primer_nombre : null,
        'segundo_nombre': segundo_nombre ? segundo_nombre : null,
        'apellido_paterno': apellido_paterno ? apellido_paterno : null,
        'apellido_materno': apellido_materno ? apellido_materno : null,
        'fecha_nacimiento': fecha_nacimiento ? fecha_nacimiento : null,
        'genero': genero ? genero : null,
        'celular': celular ? celular : null,
      },
      'email': email ? email : null,
      'password': password ? password : null,
    });
  };

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  render = () => {
    const { is_authenticated, errores_registro, guardar_registro } = this.props;
    const { email, password, primer_nombre, segundo_nombre, apellido_paterno,
      apellido_materno, fecha_nacimiento, genero, celular, loading_guardar } = this.state;

    if (is_authenticated) {
      return <Redirect to="/" />;
    }
    if (Object.entries(errores_registro).length > 0) {
      loading_guardar.close();
    }
    if (Object.entries(guardar_registro).length > 0) {
      limpiarMensajes();
      loading_guardar.close();
      SweetAlert.fire({
        title: 'Cuenta creada',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: guardar_registro.message,
        icon: 'success',
        confirmButtonText: 'Iniciar Sesión <i className="fas fa-sign-in-alt"></i>',
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      }).then(() => {
        this.props.history.push('/inicio-sesion');
      })
    }

    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo={'Crea tu Cuenta'} />
        <section className="login-section">
          <div className="auto-container">
            <div className="row clearfix">
            <div className="column col-lg-12 col-md-12 col-sm-12 text-center">
                <h4>Crea tu cuenta Contasi para comprar los servicios que tenemos disponibles</h4>
                <h5>Si ya tienes una cuenta creada, puedes iniciar sesión en el <Link to="/inicio-sesion">siguiente link</Link>.</h5>
              </div>
              <div className="column col-lg-12 col-md-12 col-sm-12 mt-5">
                <h2>Crea tu Cuenta Contasi</h2>
                <div className="login-form register-form">
                  <form className="row">
                    <div className="col-sm-12 text-right">
                      <p><span className="text-danger">*</span> datos obligatorios</p>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Primer nombre <span className="text-danger">*</span></label>
                        <input type="text" name="primer_nombre" placeholder="Ingresa primer nombre" onChange={this.onChange} value={primer_nombre} className={`form-control ${errores_registro && errores_registro.data && errores_registro.data.perfil && 'primer_nombre' in errores_registro.data.perfil ? 'is-invalid' : ''}`} />
                        {errores_registro && errores_registro.data && errores_registro.data.perfil && 'primer_nombre' in errores_registro.data.perfil ?
                          errores_registro.data.perfil.primer_nombre.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                      <div className="form-group">
                        <label>Segundo nombre</label>
                        <input type="text" name="segundo_nombre" placeholder="Ingresa segundo nombre" onChange={this.onChange} value={segundo_nombre} className={`form-control ${errores_registro && errores_registro.data && errores_registro.data.perfil && 'segundo_nombre' in errores_registro.data.perfil ? 'is-invalid' : ''}`} />
                        {errores_registro && errores_registro.data && errores_registro.data.perfil && 'segundo_nombre' in errores_registro.data.perfil ?
                          errores_registro.data.perfil.segundo_nombre.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                      <div className="form-group">
                        <label>Apellido paterno <span className="text-danger">*</span></label>
                        <input type="text" name="apellido_paterno" placeholder="Ingresa apellido paterno" onChange={this.onChange} value={apellido_paterno} className={`form-control ${errores_registro && errores_registro.data && errores_registro.data.perfil && 'apellido_paterno' in errores_registro.data.perfil ? 'is-invalid' : ''}`} />
                        {errores_registro && errores_registro.data && errores_registro.data.perfil && 'apellido_paterno' in errores_registro.data.perfil ?
                          errores_registro.data.perfil.apellido_paterno.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                      <div className="form-group">
                        <label>Apellido materno</label>
                        <input type="text" name="apellido_materno" placeholder="Ingresa apellido materno" onChange={this.onChange} value={apellido_materno} className={`form-control ${errores_registro && errores_registro.data && errores_registro.data.perfil && 'apellido_materno' in errores_registro.data.perfil ? 'is-invalid' : ''}`} />
                        {errores_registro && errores_registro.data && errores_registro.data.perfil && 'apellido_materno' in errores_registro.data.perfil ?
                          errores_registro.data.perfil.apellido_materno.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                      <div className="form-group">
                        <label>Fecha de nacimiento <small className="text-danger">*</small></label>
                        <InputMask name="fecha_nacimiento" value={fecha_nacimiento} onChange={this.onChange} mask="99/99/9999" placeholder="Ingrese tu fecha de nacimiento" className={`input-mask-nac form-control ${errores_registro && errores_registro.data && errores_registro.data.perfil && 'fecha_nacimiento' in errores_registro.data.perfil ? 'is-invalid' : ''}`} />
                        {errores_registro && errores_registro.data && errores_registro.data.perfil && 'fecha_nacimiento' in errores_registro.data.perfil ?
                          errores_registro.data.perfil.fecha_nacimiento.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Género <small className="text-danger">*</small></label>
                        <select name="genero" onChange={this.onChange} value={genero} className={`form-control ${errores_registro && errores_registro.data && errores_registro.data.perfil && 'genero' in errores_registro.data.perfil ? 'is-invalid' : ''}`}>
                          <option value="">Selecciona una opción</option>
                          <option value="Femenino">Femenino</option>
                          <option value="Masculino">Masculino</option>
                        </select>
                        {errores_registro && errores_registro.data && errores_registro.data.perfil && 'genero' in errores_registro.data.perfil ?
                          errores_registro.data.perfil.genero.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                      <div className="form-group">
                        <label>Contraseña <span className="text-danger">*</span></label>
                        <input type="password" name="password" placeholder="Cree una contraseña" onChange={this.onChange} value={password} className={`form-control ${errores_registro && errores_registro.data && 'password' in errores_registro.data ? 'is-invalid' : ''}`} />
                        {errores_registro && errores_registro.data && 'password' in errores_registro.data ?
                          errores_registro.data.password.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                      <div className="form-group">
                        <label>Email <span className="text-danger">*</span></label>
                        <input type="email" name="email" placeholder="Ingresa tu email" onChange={this.onChange} value={email} className={`form-control ${errores_registro && errores_registro.data && 'email' in errores_registro.data ? 'is-invalid' : ''}`} />
                        {errores_registro && errores_registro.data && 'email' in errores_registro.data ?
                          errores_registro.data.email.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                      <div className="form-group">
                        <label>Celular <span className="text-danger">*</span></label>
                        <input type="text" name="celular" placeholder="Ingresa tu celular" onChange={this.onChange} value={celular} className={`form-control ${errores_registro && errores_registro.data && errores_registro.data.perfil && 'celular' in errores_registro.data.perfil ? 'is-invalid' : ''}`}/>
                        {errores_registro && errores_registro.data && errores_registro.data.perfil && 'celular' in errores_registro.data.perfil ?
                          errores_registro.data.perfil.celular.map((err, x) => (
                            <div key={x} className="invalid-feedback">{err}</div>
                          ))
                          : null}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group text-right">
                        <button className="theme-btn btn-style-one" type="button" onClick={this.onClickGuardarRegistro}>Crear cuenta <i className="fas fa-sign-in-alt ml-1"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

Registro.propTypes = {
  registro: PropTypes.func.isRequired,
  limpiarMensajes: PropTypes.func.isRequired,
  is_authenticated: PropTypes.bool,
  errores_registro: PropTypes.object,
  guardar_registro: PropTypes.object,
  registro_valido: PropTypes.object,
};

const mapStateToProps = state => ({
  is_authenticated: state.auth.is_authenticated,
  errores_registro: state.auth.errores_registro,
  guardar_registro: state.auth.guardar_registro,
  registro_valido: state.auth.registro_valido,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { registro, limpiarMensajes },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Registro);
