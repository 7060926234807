import { combineReducers } from 'redux';

import auth from './auth';
import cms from './cms';
import store from './store';

export default combineReducers({
  auth,
  store,
  cms,
});
