import React, { Fragment } from 'react';

import { withRouter } from 'react-router-dom';

import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';

const Error404 = ({ history }) => (
  <Fragment>
    <Header />
    <Breadcrumb titulo="Página no encontrada" />
    <section className="welcome-section">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="content-column col-lg-12 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="sec-title">
                <h2>PÁGINA NO ENCONTRADA</h2>
              </div>
              <div className="text">La página que buscas puede haber sido removida, cambiado de nombre, o temporalmente no está disponible.</div>
              <div className="pull-left">
                <button className="theme-btn btn-style-four" onClick={history.goBack}><i className="fa fa-angle-left"></i> Volver</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </Fragment >
);

export default withRouter(Error404);
