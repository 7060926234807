export const USUARIO_CARGADO = 'USUARIO_CARGADO';
export const USUARIO_CARGANDO = 'USUARIO_CARGANDO';
export const AUTENTIFICACION_FALLIDO = 'AUTENTIFICACION_FALLIDO';
export const INICIO_SESION_CORRECTO = 'INICIO_SESION_CORRECTO';
export const INICIO_SESION_FALLIDO = 'INICIO_SESION_FALLIDO';
export const CERRAR_SESION_CORRECTO = 'CERRAR_SESION_CORRECTO';
export const REGISTRO_CORRECTO = 'REGISTRO_CORRECTO';
export const REGISTRO_FALLIDO = 'REGISTRO_FALLIDO';
export const MENSAJES_LIMPIAR = 'MENSAJES_LIMPIAR';
export const CONTRASENATOKEN_NUEVA = 'CONTRASENATOKEN_NUEVA';
export const CONTRASENATOKEN_FALLIDO = 'CONTRASENATOKEN_FALLIDO';
export const CONTRASENA_NUEVA = 'CONTRASENA_NUEVA';
export const CONTRASENA_FALLIDO = 'CONTRASENA_FALLIDO';
export const TOKEN_CORRECTO = 'TOKEN_CORRECTO';
export const TOKEN_FALLIDO = 'TOKEN_FALLIDO';
export const PEDIDOS_OBTENER = 'PEDIDOS_OBTENER';
export const PEDIDOS_FALLIDO = 'PEDIDOS_FALLIDO';
export const USUARIO_ACTUALIZACION_OBTENIDO = 'USUARIO_ACTUALIZACION_OBTENIDO';
export const USUARIO_ACTUALIZACION_FALLIDO = 'USUARIO_ACTUALIZACION_FALLIDO';
