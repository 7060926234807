import React, {
  Component,
  Fragment,
} from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  guardarPostID,
  listadoPostCategoria,
} from '../../redux/actions/cms/action';
import {
  Breadcrumb,
  Footer,
  Header,
} from '../general';
import Sidebar from './sidebar';

var url_api = process.env.REACT_APP_API_URL;

export class Blog extends Component {

  componentDidMount = () => {
    const { listadoPostCategoria } = this.props;
    const { slug } = this.props.match.params;
    if (slug) {
      listadoPostCategoria(slug);
    }
  }

  setPostID = id => {
    const { guardarPostID } = this.props;
    guardarPostID(id)
  }

  render = () => {
    const { posts, categoria } = this.props;
    const createMarkup = htmlString => ({ __html: htmlString });

    return (
      <Fragment>
        <Header />
        <Breadcrumb titulo='blog' />
        <section className="blog-page-section">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-9 col-md-12">
                <h4 className="mb-0">Categoría: {categoria ? categoria.nombre : null}</h4>
                <p className="mt-0 mb-4"><Link className="text-dark" to="/blog">limpiar selección <i className="fa fa-times text-danger"></i></Link> </p>
                {posts && posts.length > 0 ? posts.map((post, x) => (
                  <div className="news-block col-lg-12 col-md-12 col-sm-12" key={x}>
                    <div className="inner-box">
                      <div className="image">
                        <Link to={`/blog/${post.slug}`} onClick={this.setPostID.bind(this, post.id)}>
                          {post.foto_nombre ?
                            <img src={`${url_api}/static/blog/${post.foto_nombre}`} alt="foto blog" />
                            : null}
                          <div className="post-date"><span>{post.text_fecha_dia}</span> {post.text_fecha_mes}</div>
                        </Link>
                      </div>
                      <div className="lower-content">
                        <h3><Link to={`/blog/${post.slug}`} onClick={this.setPostID.bind(this, post.id)}>{post.titulo}</Link></h3>
                        <div dangerouslySetInnerHTML={createMarkup(post.extracto)} />
                      </div>
                    </div>
                  </div>
                ))
                  : null}
              </div>
              <Sidebar />
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

Blog.propTypes = {
  listadoPostCategoria: PropTypes.func.isRequired,
  guardarPostID: PropTypes.func.isRequired,
  posts: PropTypes.array.isRequired,
  categoria: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  posts: state.cms.posts,
  categoria: state.cms.categoria,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      listadoPostCategoria, guardarPostID
    },
    dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
