import {
  CARRO_FALLIDO,
  CARRO_OBTENER,
  HORAS_DISPONIBLES_FALLIDO,
  HORAS_DISPONIBLES_OBTENER,
  MENSAJES_LIMPIAR,
  SERVICIO_FALLIDO,
  SERVICIO_OBTENER,
  SERVICIO_SELECCIONADO,
  SERVICIOS_FALLIDO,
  SERVICIOS_OBTENER,
} from '../actions/store/types';

const initialState = {
  servicios: [],
  servicio: {},
  horas_disponibles: [],
  servicios_error: {},
  carro_pagar: {},
  carro_pagar_error: {},
  servicio_seleccionado: {},
};

export default function store(state = initialState, action) {
  switch (action.type) {
    case SERVICIO_FALLIDO:
      return {
        ...state,
        servicio: {}
      };
    case SERVICIO_OBTENER:
      return {
        ...state,
        servicio: action.payload
      };
    case SERVICIOS_OBTENER:
      return {
        ...state,
        servicios: action.payload.servicios,
        servicios_error: {},
        servicio_seleccionado: {},
      };
    case HORAS_DISPONIBLES_FALLIDO:
      return {
        ...state,
        horas_disponibles: []
      };
    case HORAS_DISPONIBLES_OBTENER:
      return {
        ...state,
        horas_disponibles: action.payload,
      };
    case SERVICIOS_FALLIDO:
      return {
        ...state,
        servicios: [],
        servicios_error: action.payload,
        servicio_seleccionado: {},
      };
    case CARRO_OBTENER:
      return {
        ...state,
        carro_pagar_error: {},
        carro_pagar : action.payload,
      };
    case CARRO_FALLIDO:
      return {
        ...state,
        carro_pagar: {},
        carro_pagar_error : action.payload,
      };
    case MENSAJES_LIMPIAR:
      return {
        ...state,
        servicios_error: {},
      };
    case SERVICIO_SELECCIONADO:
      return {
        ...state,
        servicio_seleccionado: action.payload,
      };
    default:
      return state;
  }
}
